<template>
  <v-row v-if="isSucess">
    <v-col xs="0" sm="0" md="1" lg="2" xl="3">
    </v-col>
    <v-col xs="12" sm="12" md="10" lg="8" xl="6">
      <div class="payment-pix">
        <TitleMeansPayment v-if="!showCog" :value="outputQuantity" :coins="coins"/>
        <div v-else class="icon-container">
          <v-progress-circular indeterminate :size="60"></v-progress-circular>
        </div>
      </div>
    </v-col>
    <v-col xs="0" sm="0" md="1" lg="2" xl="3">
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src
import TitleMeansPayment from '@/components/TitleMeansPayment.vue'
import {useToast} from "vue-toastification";
import apiCaravel from '@/services/apiCaravel';

const toast = useToast();

export default {
  name: 'MeansPayment',
  components: {
    TitleMeansPayment,
  },
  data() {
    return {
      isSucess: true,
      isConnectionError: false,
      coins: [
        {name: "Pix", id: 1},
      ],
      showCog: true,
    }
  },
  computed: {
    value: {
      get() {
        return this.$store.getters.getValue;
      },
      set(value) {
        return this.$store.commit("setValue", value);
      },
    },
    charge: {
      get() {
        return this.$store.getters.getCharge;
      },
      set(value) {
        return this.$store.commit("setCharge", value);
      },
    },
    exchangeRate: {
      get() {
        return this.$store.getters.getExchangeRate;
      },
      set(value) {
        return this.$store.commit("setExchangeRate", value);
      },
    },
    exitCurrency: {
      get() {
        return this.$store.getters.getExitCurrency;
      },
      set(value) {
        return this.$store.commit("setExitCurrency", value);
      },
    },
    inputCurrency: {
      get() {
        return this.$store.getters.getInputCurrency;
      },
      set(value) {
        return this.$store.commit("setInputCurrency", value);
      },
    },
    outputQuantity: {
      get() {
        return this.$store.getters.getOutputQuantity;
      },
      set(value) {
        return this.$store.commit("setOutputQuantity", value);
      },
    },
    merchantTransaction: {
      get() {
        return this.$store.getters.getMerchantTransaction;
      },
      set(value) {
        return this.$store.commit("setMerchantTransaction", value);
      },
    },
    token: {
      get() {
        return this.$store.getters.getToken;
      },
      set(value) {
        return this.$store.commit("setToken", value);
      },
    },
    country: {
      get() {
        return this.$store.getters.getCountry;
      },
      set(value) {
        return this.$store.commit("setCountry", value);
      },
    },
    currency: {
      get() {
        return this.$store.getters.getCurrency;
      },
      set(value) {
        return this.$store.commit("setCurrency", value);
      },
    },
    partner: {
      get() {
        return this.$store.getters.getPartner;
      },
      set(value) {
        return this.$store.commit("setPartner", value);
      },
    },    
    errorMessage: {
      get() {
        return this.$store.getters.getErrorMessage;
      },
      set(value) {
        return this.$store.commit("setErrorMessage", value);
      },
    },
  },
  async mounted() {
    document.title = 'MeansPayment | CaravelPay'
    this.errorMessage = ""

    let query = this.getQueryParams()

    if (query['partner']){
      this.partner=query.partner
    }
    else{
      this.partner=1
    }

    this.inputCurrency = query.currency
    this.exitCurrency = query.destinationCurrency
    this.value = query.amount
    this.charge = query.charge
    this.merchantTransaction = query.transactionId
    this.country = query.country
    this.currency = query.coin
    this.token = 'Bearer ' + this.$route.params.token
    var headers = {}
    headers['Authorization'] = this.token

    try {
      await apiCaravel.get('/api/merchant/1/get_verification_token', {headers})
    } catch (error) {
      console.log('code', error.code)
      this.errorMessage = error.message.toUpperCase()
      this.$router.push('/payments/error/');
      this.isSucess = false
      this.isConnectionError = true
    }
    if (!this.isConnectionError) {
      if (this.value !== undefined && this.value.includes(',')) {
        this.errorMessage = "The amount parameter cannot contain a comma.\nCorrect and try again.".toUpperCase()
        this.$router.push('/payments/error/');
        this.isSucess = false
      } else if (this.charge !== undefined && this.charge.includes(',')) {
        this.errorMessage = "The charge parameter cannot contain a comma.\nCorrect and try again.".toUpperCase()
        this.$router.push('/payments/error/');
        this.isSucess = false
      } else {
        var payload = {}
        if (this.value !== undefined) {
          payload = {
            input_currency: this.inputCurrency,
            exit_currency: this.exitCurrency,
            value: this.value.replace('.', ','),
            charge: 0
          }
        } else if (this.charge !== undefined) {
          payload = {
            input_currency : this.inputCurrency,
            exit_currency: this.exitCurrency,
            value: 0,
            charge: this.charge.replace('.', ',')
          }
        }
        var url = ''
        if(this.partner){
          url = '/api/hooks/blindpay/partner/quotes/payin'
        } else {
          url = '/api/hooks/blindpay/quotes/payin'
        }
        url = '/api/hooks/sm/exchange/cotacao/principal'
        
        console.log("Valor de partner.",this.partner)
        console.log(payload)
        await apiCaravel.post(url, payload).then((response) => {
          console.log(response.data)
          if(this.partner==0){
            if ((parseFloat(payload['value'].toString().replace(',', '.')) != 0 &&
                (parseFloat(payload['value'].toString().replace(',', '.')) < response.data['quantidadeMinimaSaida'] * response.data['precoUnitarioSaida'] &&
                 parseFloat(payload['value'].toString().replace(',', '.')) < response.data['quantidadeMinimaEntrada'])) ||
                (parseFloat(payload['charge'].toString().replace(',', '.')) != 0 &&
                (parseFloat(payload['charge'].toString().replace(',', '.')) < response.data['quantidadeMinimaEntrada']/response.data['precoUnitarioSaida'] &&
                 parseFloat(payload['charge'].toString().replace(',', '.')) < response.data['quantidadeMinimaSaida'])))
            {
              toast.warning("Value adjusted to minimum quotation allowed.")
            }
          }
          this.value = response.data['quantidadeSaida']
          this.charge = response.data['quantidadeEntrada']
          this.exchangeRate = Number(response.data['precoUnitarioSaida']).toFixed(2)
          this.outputQuantity = Number(response.data['quantidadeEntrada']).toFixed(2)
          this.showCog = false
          console.log(this.value)
        }).catch((error) => {
          console.log("Erro gerado.",error)
          this.errorMessage = "Partner system that quotes offline. Contact your system administrator.".toUpperCase()
          this.$router.push('/payments/error/');
          this.isSucess = false
        })
      }
    }
  },
  created() {
    this.$store.dispatch('setDuration', 20)
    this.$store.dispatch('setStartTime')
  },
  methods: {
    getQueryParams: function (name, query) {
      query = location.search.slice(1)
      var partes = query.split('&')
      var data = {}
      partes.forEach(function (parte) {
        var chaveValor = parte.split('=')
        data[chaveValor[0]] = chaveValor[1]
      })
      return data
    }
  }
}
</script>
<style scoped lang="scss">
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
</style>